import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Main } from '@app/Main'
import { Cloak } from '@pages/Cloak'

import { useAppStore } from '../store'
import './global.css'

export const StartUp = () => {
  const { setInfo } = useAppStore()

  useEffect(() => {
    const domain = window.location.hostname

    fetch(`./${domain}.json`)
      .then(response => response.json())
      .then(json => setInfo(json))
      .catch(error => console.error('Error reading file:', error))
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/white-page' element={<Cloak />} />
      </Routes>
    </BrowserRouter>
  )
}
