import { create } from 'zustand'
import { AppType } from '@shared/types'

export type AppStoreType = {
  info: AppType | null
  setInfo: (info: AppType) => void
}
export const useAppStore = create<AppStoreType>(set => ({
  info: null,
  setInfo: data =>
    set(() => ({
      info: data,
    })),
}))
