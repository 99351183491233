import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

// HeadBlock
export const HeadBlock = styled.div`
  display: flex;
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImgStyled = styled.img`
  border-radius: 25%;
  border-width: 0;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0 1px 2px 0,
    rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
  margin: 7px 24px 0 0;
  width: 72px;
`

export const MainTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2rem;
`

export const SubTitle = styled.a`
  fill: rgb(1, 135, 95);
  stop-color: rgb(1, 135, 95);
  color: rgb(1, 135, 95);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.00625em;
  line-height: 1.5rem;
`

export const IncTitle = styled.span`
  fill: rgb(95, 99, 104); // ?
  stop-color: rgb(95, 99, 104); // ?
  color: rgb(95, 99, 104);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1rem;
`

// HeadBlock

// ListBlock

export const ListBlock = styled.div`
  display: flex;
  margin: 16px 0 24px 0;
  padding: 12px 0;

  overflow-x: auto;
  width: 100%;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  align-items: center;

  min-width: 96px;
  position: relative;

  &:not(:first-child)::before {
    background-color: rgb(232, 234, 237);
    content: '';
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: calc(50% - 12px);
    width: 1px;
  }
`

export const ContentItem = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(32, 33, 36);
  letter-spacing: 0.0178571em;
  line-height: 1.25rem;
  height: 24px;

  white-space: nowrap;
`

export const DescriptionItem = styled.div`
  color: rgb(95, 99, 104);
  font-size: 0.75rem;
  font-weight: 400;
  height: 20px;

  white-space: nowrap;
`

export const ListIcon = styled.img`
  background-size: contain;
  height: 16px;
`

// ListBlock

// ActionBlock

export const ActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`

export const InstallButton = styled.button<{ $isInstall?: boolean }>`
  align-items: center;
  appearance: none;
  background: rgb(1, 135, 95); //${({ $isInstall }) =>
    $isInstall ? 'rgb(1, 135, 95)' : 'rgb(165, 167, 175)'};
  border: none;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.0178571em;
  line-height: 1.25rem;
  min-height: 36px;
  min-width: 160px;
  outline: none;
  overflow: visible;
  padding: 8px 16px;
  position: relative;
`

export const AddListButton = styled.button`
  align-items: center;
  display: inline-flex;
  gap: 10px;
  appearance: none;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(1, 135, 95);
  cursor: default;
  font-size: 0.875rem;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.0107143em;
  line-height: inherit;
  margin: 6px 0;
  min-height: 40px;
  min-width: 64px;
  outline: none;
  overflow: visible;
  padding: 0 8px;
`

// ActionBlock

// SubDescription

export const SubDescription = styled.span`
  fill: rgb(95, 99, 104);
  stop-color: rgb(95, 99, 104);
  color: rgb(95, 99, 104);
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857em;
  line-height: 1.25rem;
  padding: 4px 0;
`
